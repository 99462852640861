import React, { useState, useEffect, useRef } from 'react';
import '../Styles/Home.css';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import ConnectWithUsPopUp from '../Layouts/ConnectWithUsPopUp';
import Navbar from '../Layouts/Navbar';
import Footer from '../Layouts/Footer';
import HomeSlider from './HomeSlider';
import Swal from 'sweetalert2';

const Home = () => {

    const features = ['IoT- Industry 4.0 ready', 'Intelligent Scheduling', 'Data-Driven Analytics'];
    const emailUrl = process.env.REACT_APP_EMAIL_SERVER_ADDRESS;
    const navigate = useNavigate();
    // console.log('Email Server Address:', process.env.REACT_APP_EMAIL_SERVER_ADDRESS);
    const travo500 = () => {
        navigate('/travo')
    };

    const fleetmanager = () => {
        navigate('/fleetmanager')
    }

    const [emailData, setEmailData] = useState({
        from: '',
        subject: 'Reg: AMR',
        organization: '',
        name: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const text = 
            `Name: ${emailData.name}\nEmail: ${emailData.from}\nOrganization: ${emailData.organization}\nMessage: ${emailData.message}`;
    
        let swalInstance;
    
        try {
            // Show a loading toast for "Connecting"
            swalInstance = Swal.fire({
                toast: true,
                position: 'bottom-end',
                icon: 'info',
                title: 'Connecting with us...',
                showConfirmButton: false,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading(); // Start the spinner
                },
            });
    
            // Make the async API request
            const response = await fetch(emailUrl + 'send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...emailData,
                    message: text,
                }),
            });
    
            // Check if the response is OK
            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }
    
            const result = await response.json();
    
            // Update Swal to success
            swalInstance.update({
                icon: 'success',
                title: 'Message Sent!',
                text: 'Thank you for connecting with us. We will get back to you shortly!',
                showConfirmButton: false,
                timer: 4000,
            });
    
            console.log('Email sent successfully:', result);
    
            // Reset the email data
            setEmailData({
                from: '',
                name: '',
                subject: 'Inquiry from Connect With Us',
                organization: '',
                message: '',
            });
        } catch (error) {
            console.error('Failed to send email:', error.message);
    
            // Update Swal to error
            swalInstance.update({
                icon: 'error',
                title: 'Connection Failed!',
                text: 'We couldn’t send your message. Please try again later.',
                showConfirmButton: false,
                timer: 4000,
            });
        } finally {
            // Stop showing the spinner after a delay
            setTimeout(() => {
                Swal.close();
            }, 4000);
        }
    };
    
    
    
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const splitText = (text) => {
        return text.split('').map((char, index) => (
            <motion.span
                key={index}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 0.02 }}
            >
                {char}
            </motion.span>


        ));
    };

    return (
        <div>
            <Navbar />
            {/*-----------------------Landing Video----------------------D:\MyProjects\new\amr_web_site\src\Assests\Videos\WebsiteBackground.mp4-----*/}
            <section className="LandingVideo" id='home'>
                <div className="video-gradient-overlay"></div>
                <video className="landing-video" autoPlay muted loop>
                    <source src={require('../Assests/Videos/WebsiteBackground.mp4')} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="video-overlay-content">
                    <h1>{splitText('NexStride Robotics')}</h1>
                    <p>{splitText('Sensible Mobility')}</p>
                </div>
            </section>

            {/*-----------------------Future of Mobility------------------------*/}

            <section className="HomeContent">

                <div className="gradient-div">
                    <div className="fixed-width-div">
                        <div className="grid-container">
                            <div className="grid-item left">
                                <h1>The Future of Mobility is here</h1>
                                <h3>Productivity | Efficiency | Innovation</h3>
                                <p>
                                    We’re creating a future where mobility transforms industries and empowers human potential.
                                    With a belief that mobility is more than just movement, we design autonomous solutions that
                                    enhance productivity, improve efficiency, and unlock new possibilities.
                                </p>
                                <p>
                                    Our robots are built to navigate complex environments with precision, reliability, and purpose,
                                    helping businesses achieve more with less.
                                </p>
                            </div>

                            <div className="grid-item right">
                                <video className="Travo-video" autoPlay muted loop>
                                    <source src={require('../Assests/Videos/Sequence_01.webm')} type="video/mp4" />
                                </video>
                            </div>


                        </div>
                    </div>
                </div>

                {/*----------travo 500--------------*/}

                <div id='products' className="travo500Div">
                    <div className="travo-grid">
                        <div className="grid-box left-box">
                            <img
                                src={require('../Assests/Images/Common images/TravoHome.jpg')}
                                alt="Travo 500"
                            />
                        </div>
                        <div className="grid-box right-box">
                            <h3>Travo 500</h3>
                            <p>
                                Travo 500 is designed to tackle labor shortages by efficiently
                                moving trolleys in manufacturing and assembly logistics. With
                                its reliable performance and advanced navigation, Travo 500
                                streamlines operations and enhances productivity in industrial
                                environments.
                            </p>

                            <div className="features">
                                {['Payload 500kg', 'Max Speed 1.5 m/s', 'Autonomous Docking'].map((feature, index) => (
                                    <motion.div
                                        key={index}
                                        className="feature-box"
                                        style={{ marginLeft: `${index * 50}px` }}
                                        initial={{ opacity: 0, x: 50 }}
                                        whileInView={{ opacity: 1, x: 0 }}
                                        viewport={{ once: true, amount: 0.5 }}
                                        transition={{ delay: index * 0.3, duration: 0.5 }}
                                    >
                                        <p>{feature}</p>
                                    </motion.div>
                                ))}
                            </div>

                            <div className="buttoncontainer">
                                <button className="learn-more" onClick={travo500}>
                                    <span className="circle" aria-hidden="true">
                                        <span className="icon arrow"></span>
                                    </span>
                                    <span className="button-text">Know more</span>
                                </button>
                            </div>


                        </div>
                    </div>
                </div>

                {/*----------NXS Fleet manager--------------*/}

                <div className="fleetManagerDiv">
                    <div className="fleet-grid">

                        <div className="grid-box fleet-right-box">
                            <img
                                src={require('../Assests/Images/Common images/fleetsoftwarehome.jpg')}
                                alt="Fleet Manager"
                            />
                        </div>
                        <div className="grid-box fleet-left-box">
                            <h3>NXS Fleet Manager</h3>
                            <p>
                                NXS Fleet Software revolutionizes autonomous robot
                                management with intelligent task scheduling, real time
                                monitoring, and safety features like geo-fencing & collision
                                prevention. Its intuitive interface and advanced analytics
                                maximize productivity and efficiency with ease.
                            </p>

                            <div className="features">
                                {features.map((feature, index) => (
                                    <motion.div
                                        key={index}
                                        className="feature-box"
                                        style={{ marginRight: `${index * 50}px` }}
                                        initial={{ opacity: 0, x: -50 }}
                                        whileInView={{ opacity: 1, x: 0 }}
                                        viewport={{ once: true, amount: 0.5 }}
                                        transition={{ delay: index * 0.3, duration: 0.5 }}
                                    >
                                        <p>{feature}</p>
                                    </motion.div>
                                ))}
                            </div>
                            <div className="buttoncontainer">
                                <button class="learn-more" onClick={fleetmanager}>
                                    <span class="circle" aria-hidden="true">
                                        <span class="icon arrow"></span>
                                    </span>
                                    <span class="button-text">Know more</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------Connect with us Button part-----------------------------------*/}

                <ConnectWithUsPopUp />

                {/*---------------------About us--------------------------------------------------------*/}

                <div id='about' className="aboutUsDiv">
                    <div className="aboutUsGlassOverlay">
                        <div className="aboutUsGrid">
                            <div className="aboutUsContent">
                                <h2>About Us</h2>
                                <p>
                                    We are a tech-driven startup transforming manufacturing with
                                    cutting-edge Autonomous Mobile Robots (AMRs). <br /><br></br>
                                    Our intelligent, adaptable robots seamlessly integrate into
                                    workflows, enhancing efficiency, safety, and scalability. <br /><br></br>
                                    Equipped with advanced navigation, vision, and sensors, they
                                    navigate dynamic environments and handle complex tasks effortlessly. <br /><br></br>
                                    Partner with us to revolutionize manufacturing with smart,
                                    autonomous robotics.
                                </p>
                            </div>

                            <div className="aboutUsList">
                                <div className="aboutUsBox about-box1">
                                    <h3>Optimize Workflow</h3>
                                    <p>
                                        Our robots automate material handling, reduce downtime, and
                                        enhance production speed, enabling teams to focus on
                                        high-value tasks.
                                    </p>
                                </div>
                                <div className="aboutUsBox about-box2">
                                    <h3>Enhance Safety</h3>
                                    <p>
                                        By deploying AMRs in critical processes, manufacturers can
                                        minimize human exposure to hazardous conditions, reducing
                                        workplace incidents.
                                    </p>
                                </div>
                                <div className="aboutUsBox about-box3">
                                    <h3>Scale Efficiently</h3>
                                    <p>
                                        Our scalable, modular systems allow companies to expand their
                                        automation capabilities without overhauling existing
                                        infrastructure.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*------------------Carousel Div-------------------------------------------------*/}

                <div className="Homemain-container">
                    <div className="HomecontentDiv">
                        <p>Discover and experience our journey to bring the <b style={{ color: '#0083b3' }}>sensible mobility</b> to you.</p>
                    </div>

                    <div className="Homeslider-Homecontainer">
                        {/* <HomeSlider/> */}
                        <div>
                            <video className="Home-features-video" autoPlay muted loop>
                                <source src={require('../Assests/Videos/Problem & Solution V1.1.mp4')} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                       
                    </div>
                </div>


                {/*------------------Connect With Us Div-------------------------------------------*/}

                <div id='contact' className="ConnectWithUsDiv">
                    <div className="leftContent">
                        <h2>Connect With Us</h2>
                        <form className="connectForm" onSubmit={handleSubmit}>
                            <div className="row">
                                <input type="text" id="name" name="name" value={emailData.name} onChange={handleChange} placeholder="First Name" required />
                                <input type="email" id="email" name='from' value={emailData.from} onChange={handleChange} placeholder="Email Address" required />
                            </div>
                            <div className="row">
                                <input type="text" id="subject" name='organization' value={emailData.organization} onChange={handleChange} placeholder="Organization" required />
                            </div>
                            <div className="row">
                                <input type="text" id="message" name='message' value={emailData.message} onChange={handleChange} placeholder="Message" required />
                            </div>


                            <div className="submitbuttoncontainer">
                                <button class="submit-btn">
                                    <span class="submitcircle" aria-hidden="true" >
                                        <span class="submiticon submitarrow"></span>
                                    </span>
                                    <span class="submitbutton-text">Submit</span>
                                </button>
                            </div>


                        </form>
                    </div>
                    <div className="rightImage"></div>
                </div>
            </section>

            <Footer />

        </div>

    );
};

export default Home;
