import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Travo500 from './Pages/Travo500';
import FleetManager from './Pages/FleetManager';
import Test from './Pages/test';
import HomeSlider from './Pages/HomeSlider';


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/travo' element={<Travo500 />} />
          {/* <Route path='/test' element={<CardCarousel />} /> */}
          <Route path='/test' element={<Test />} />
          <Route path='/homeslider' element={<HomeSlider />} />
          <Route path='/fleetmanager' element = {<FleetManager/>} />
        </Routes>
      </Router>
    </div>

  );
}

export default App;
