import React, { useState, useEffect, useRef } from 'react';
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import lidarImage from '../Assests/Images/BG images/lidarImage.jpg';
import loadedTrollyImage from '../Assests/Images/BG images/Travo_LoadedTrolly.jpg';
import connectUsImage from '../Assests/Images/Common images/connectus.jpg';
import fleetSoftwareHomeImage from '../Assests/Images/Common images/fleetsoftwarehome.jpg';
import '../Styles/test.css';


// const FloatingButton = ({ onClick, icon, style }) => (
//   <button
//     onClick={onClick}
//     className='travoSliderArrow'
//     style={{
//       ...style,
//     }}
//   >
//     <FontAwesomeIcon icon={icon} style={{ color: "#fff", fontSize: "20px" }} />
//   </button>
// );


function Pagination(props) {
    const { centerSlideDataIndex, updatePosition } = props;
    console.log(" centerSlideDataIndex--000", centerSlideDataIndex);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginTop: 20
        }}
      >
        {data.map((_, index) => {
          const isCenterSlide = props.centerSlideDataIndex === index;
          return (
            <div
              onClick={() => {
                updatePosition(index);
              }}
              style={{
                height: 5,
                width: 45,
                borderRadius: "30px",
                background: isCenterSlide ? "#0083b3" : "#aaa",
                border: "1px solid black",
                cursor: "pointer"
              }}
            />
          );
        })}
      </div>
    );
  }  

export const data = [
  {
    cover: lidarImage,
    title: "Lidar Image",
  },
  {
    cover: loadedTrollyImage, 
    title: "Travo Loaded Trolly",
  },
  {
    cover: connectUsImage,
    title: "Connect Us",
  },
  {
    cover: fleetSoftwareHomeImage,
    title: "Fleet Software Home",
  },
];

const HomeSlider = () => {
  const ref = React.useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
  const onCenterSlideDataIndexChange = (newIndex) => {
    setCenterSlideDataIndex(newIndex);
  };

  const updatePosition = (index) => {
    console.log("🚀 ~ ition ~ index", index);
    console.log("🚀 ~centerSlideDataIndex--111", centerSlideDataIndex);
    console.log("🚀 subtract", index - centerSlideDataIndex);
    ref?.current?.swipeTo(index - centerSlideDataIndex);
  };


  const [isHovered, setIsHovered] = useState(false);

  // Set interval for autoplay
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        ref.current?.goNext();
      }
    }, 3000); // Change slides every 3 seconds

    return () => clearInterval(interval); // Clear the interval when component unmounts
  }, [isHovered]);

  return (
    <div>
      <div style={{ width: "100%", position: "relative" }}>
        <ResponsiveContainer
          carouselRef={ref}
          render={(parentWidth, carouselRef) => {
            let currentVisibleSlide = 3;
            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={(props) => (
                  <Card {...props} setIsHovered={setIsHovered} />
                )}
                slideWidth={parentWidth < 800 ? parentWidth - 100 : 750}
                carouselWidth={parentWidth}
                data={data}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={5}
                useGrabCursor
                onActiveSlideChange={onCenterSlideDataIndexChange}
              />
            );
          }}
        />
        {/* <>
          <FloatingButton
            onClick={() => {
              ref.current?.goBack();
            }}
            icon={faArrowLeft}
            style={{ left: 10 }}
          />
          <FloatingButton
            onClick={() => {
              ref.current?.goNext(6);
            }}
            icon={faArrowRight}
            style={{ right: 10 }}
          />
        </> */}
      </div>
      <Pagination
        updatePosition={updatePosition}
        centerSlideDataIndex={centerSlideDataIndex}
      />
    </div>
  );
};

// Memoized Card Component
export const Card = React.memo(function (props) {
  const { data, dataIndex, setIsHovered } = props;
  const { cover } = data[dataIndex];

  return (
    <div
      style={{
        width: "100%",
        userSelect: "none",
      }}
      className="my-slide-component"
      onMouseEnter={() => setIsHovered(true)} // Stop autoplay when hovering
      onMouseLeave={() => setIsHovered(false)} // Resume autoplay when hover ends
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          borderRadius: 25,
        }}
        draggable={false}
        src={cover}
        alt="No Image"
      />
    </div>
  );
});

export default HomeSlider;
