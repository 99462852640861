import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';

const PopUp = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const emailUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const [emailData, setEmailData] = useState({
        from: '',
        subject: 'Reg: AMR',
        organization: '',
        name: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsModalOpen(false);
        const text = 
        `Name: ${emailData.name}\nEmail: ${emailData.from}\nOrganization: ${emailData.organization}\nMessage: ${emailData.message}`;

    let swalInstance;

    try {
        // Show a loading toast for "Connecting"
        swalInstance = Swal.fire({
            toast: true,
            position: 'bottom-end',
            icon: 'info',
            title: 'Connecting with us...',
            showConfirmButton: false,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading(); // Start the spinner
            },
        });

        // Make the async API request
        const response = await fetch(emailUrl + 'send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...emailData,
                message: text,
            }),
        });

        // Check if the response is OK
        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();

        // Update Swal to success
        swalInstance.update({
            icon: 'success',
            title: 'Message Sent!',
            text: 'Thank you for connecting with us. We will get back to you shortly!',
            showConfirmButton: false,
            timer: 4000,
        });

        console.log('Email sent successfully:', result);

        // Reset the email data
        setEmailData({
            from: '',
            name: '',
            subject: 'Inquiry from Connect With Us',
            organization: '',
            message: '',
        });
    } catch (error) {
        console.error('Failed to send email:', error.message);

        // Update Swal to error
        swalInstance.update({
            icon: 'error',
            title: 'Connection Failed!',
            text: 'We couldn’t send your message. Please try again later.',
            showConfirmButton: false,
            timer: 4000,
        });
    } finally {
        // Stop showing the spinner after a delay
        setTimeout(() => {
            Swal.close();
        }, 4000);
    }
    };



    return (
        <div>
            {/*---------------------Connect with us Button part-----------------------------------*/}

            <div className='connectWithUsBtn'>
                <div className='connectWithUsTextContent'>
                    <div className='connectWithUsText'>
                        <h3>Interested? To experience the future now</h3>
                    </div>
                    <button className='connectWithUsButton' onClick={openModal}><h3>Connect with us</h3></button>
                </div>
            </div>
            {/*----------------Connect with us form--------------------------*/}

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Connect With Us</h2>
                        <form
                            className="connectForm"
                            onSubmit={handleSubmit}
                        >
                            <div className="row">
                                <input type="text" id="name" name="name" value={emailData.name} onChange={handleChange} placeholder="First Name" required />
                                <input type="email" id="email" name='from' value={emailData.from} onChange={handleChange} placeholder="Email Address" required />
                            </div>
                            <div className="row">
                                <input type="text" id="subject" name='organization' value={emailData.organization} onChange={handleChange} placeholder="Organization" required />
                            </div>
                            <div className="row">
                                <input type="text" id="message" name='message' value={emailData.message} onChange={handleChange} placeholder="Message" required />
                            </div>


                            <div className="formsubmitbuttoncontainer">
                                <button className="formsubmitlearn-more">
                                    <span className="formsubmitcircle" aria-hidden="true">
                                        <span className="formsubmiticon formsubmitarrow"></span>
                                    </span>
                                    <span className="formsubmitbutton-text">Submit</span>
                                </button>
                            </div>
                        </form>
                        <button className="close-button" onClick={closeModal}></button>
                    </div>
                </div>
            )
            }

        </div >
    )
};

export default PopUp;