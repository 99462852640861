import React, { useState, useEffect, useRef } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import "../Styles/Navbar.css";

const Navbar = () => {
    const [isActive, setIsActive] = useState(false);
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const menuRef = useRef(null); // Ref for the menu

    const toggleMenu = () => {
        setIsActive((prev) => !prev);
    };

    const closeMenu = () => {
        setIsActive(false);
        setIsDropdownActive(false);
    };

    const handleDropdownEnter = () => {
        setIsDropdownActive(true);
    };

    const handleDropdownLeave = () => {
        setIsDropdownActive(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <nav className="nav-area" ref={menuRef}>

          {/*------------------------Navbar Logo---------------------------------------   */}
            <div className="logo">
                <img src={require('../Assests/Images/Logo/Logo_White_PNG.png')} alt="Logo" />
            </div>

            <div className={`hamburger ${isActive ? "active" : ""}`} onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>

            {/*------------------------Navbar Contents---------------------------------------   */}
            <ul className={`menu ${isActive ? "active" : ""}`}>
                {isActive && (
                    <div className="logo">
                        <img src={require('../Assests/Images/Logo/Logo_White_PNG.png')} alt="Logo" />
                    </div>
                )}
                <li><Link className='nav-contents' to="/#home" onClick={closeMenu} smooth>Home</Link></li>
              
                <li
                    onMouseEnter={handleDropdownEnter}
                    onMouseLeave={handleDropdownLeave}
                >
                    <a href="/#">
                        Products
                        <i className={isDropdownActive ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
                    </a>
                    {isDropdownActive && (
                        <ul className="nav-dropdown">
                            <li><a href="/travo" onClick={closeMenu}>Travo 500</a></li> 
                            <li><a href="/fleetmanager" onClick={closeMenu}>NXS Fleet Manager</a></li> 
                        </ul>
                    )}
                </li>
                <li><Link className='nav-contents' to="/#about" onClick={closeMenu} smooth>About Us</Link></li>
                <li><Link id='connect-link' className='nav-contents' to="/#contact" onClick={closeMenu} smooth>Connect with Us</Link></li>
            </ul>
        </nav>
    );
  }
export default Navbar;
