import React, { useState, useEffect, useRef } from 'react';
import '../Styles/Travo500.css';
import 'font-awesome/css/font-awesome.min.css';
import Navbar from '../Layouts/Navbar';
import Footer from '../Layouts/Footer';
import Test from './test';
import ConnectWithUsPopUp from '../Layouts/ConnectWithUsPopUp';
import { motion } from "framer-motion";


const Travo500 = () => {

    const cardsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible");
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        cardsRef.current.forEach((card) => {
            if (card) observer.observe(card);
        });

        return () => {
            cardsRef.current.forEach((card) => {
                if (card) observer.unobserve(card);
            });
        };
    }, []);



    const [scrollPercent, setScrollPercent] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
        const percentage = (scrollPosition / scrollHeight) * 100;
  
        // Map scroll percentage to 0-100% for animation
        setScrollPercent(Math.min(percentage, 100));
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const splitText = (text) => {
        return text.split('').map((char, index) => (
            <motion.span
                key={index}
                initial={{ x: -20, opacity: 0 }} 
                animate={{ x: 0, opacity: 1 }} 
                transition={{ duration: 0.5, delay: index * 0.02 }} 
            >
                {char}
            </motion.span>
        
        
        ));
    };


    return (

        <div>
            <Navbar />
            <section className="Travo500LandingImg">
                <div className="content">
                    <h1>{splitText('Travo 500')}</h1>
                    <p>{splitText('Pulling more than just weight, we pull productivity')}</p>
                    <a
                        href="/Documents/AMR Brochure_Digital Copy.pdf"
                        download
                        className="download-btn"
                    >

                        Download Spec Sheet
                        <i className="fa fa-download" style={{ marginRight: '8px' }}></i>
                    </a>
                </div>
            </section>

            <section className='travoContent'>

                {/*------------------Travo Landing Image------------------*/}
                <div className="Travogradient">
                    <div className="travoInnerDivContent">
                        <p>
                            Travo-500 is a fully autonomous robot designed and engineered for tugging
                            operations in diverse industries. Travo-500 is designed to streamline operations
                            and reduce labour requirements for repetitive tasks
                        </p>
                    </div>
                </div>

                {/*----------------Animation Part--------------------*/}
                {/* <div className="scroll-container">
                    <div
                        className="Animation1Div"
                        style={{
                            transform: `translateY(${100 - scrollPercent}%)`,
                        }}
                    >
                        <img src={require('../Assests/Images/AMR images/TopView.png')} alt="Top View" />
                    </div>
                </div> */}

                {/*-------------------Travo features-----------------*/}

                <div className="travoFeaturesDiv">
                    {[
                        { icon: "Asset 1", title: "8 Hrs", description: "Capable of running for 8 hours straight, it effortlessly tugs loads of up to 500 kg, ensuring reliability and efficiency for heavy tasks.", direction: "right" },
                        { icon: "Asset 2", title: "Max Speed", description: "Reaches a maximum speed of 1.5 m/s, providing quick and reliable movement for optimal task efficiency and productivity.", direction: "right" },
                        { icon: "Asset 3", title: "Payload", description: "Capable of towing loads up to 500 kg, designed for heavy-duty tasks with reliable strength and performance.", direction: "right" },
                        { icon: "Asset 4", title: "Triple", description: "Triple-layered protection system ensuring optimal safety and maximum reliability for every operation.", direction: "left" },
                        { icon: "Asset 5", title: "Autonomous", description: "Robot automatically recharges itself ensuring uninterrupted operations with no manual intervention required for charging.", direction: "left" },
                        { icon: "Asset 6", title: "Navigation", description: "Operates with SLAM (Simultaneous Localization and Mapping) for accurate navigation without reliance on external markers.", direction: "left" },
                    ].map((feature, index) => (
                        <div
                            key={index}
                            className="card"
                            data-icon={feature.icon}
                            data-animate={feature.direction}
                            ref={(el) => (cardsRef.current[index] = el)}
                        >
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>



                {/*---------------------Connect with us button-----------------*/}

                <ConnectWithUsPopUp />

                {/*------------------------Carousel Slider----------------------*/}

                <div className='TravoSlider'>
                    <Test />
                </div>

                {/*--------------------Download Spec Sheet---------------------*/}

                <div className="DownloadSpecSheetDiv">
                    <div className="DownloadSpecSheetGlassOverlay">
                        <div className="InnerDownloadGradient">
                            <div className='connectWithUsText'>
                                <h3>Interested? Discover the full specifications</h3>
                            </div>
                            <button className='connectWithUsButton downloadspecbutton'
                                onClick={() => {
                                    const link = document.createElement('a');
                                    link.href = '/Documents/AMR Brochure_Digital Copy.pdf';
                                    link.download = 'AMR Brochure_Digital Copy.pdf';
                                    link.click();
                                }}
                            ><h3>Download Spec Sheet <i className="fa fa-download downloadicon" style={{ marginRight: '8px' }}></i></h3>

                            </button>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </div >
    );
};

export default Travo500;