import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import "../Styles/Footer.css";

function Footer() {
    return (
      <footer className="footer-container">
        <div className="footer-row">
          {/*-------------------------------Address--------------------------------------- */}
          <div className="footer-col">
            <h5>Contact Us</h5>
            <p>
              NexStride Robotics,<br />
              Plot 164, 5th A Cross,<br />
              Bommasandra Industrial Area,<br />
              Bengaluru, Karnataka 560099
            </p>
            <p>Email: sales@nexstriderobotics.com</p>
          </div>
  
          {/*------------------------------Social Media Links----------------------------- */}
          <div className="footer-col">
            <h5>Follow Us</h5>
            <a
              href="https://www.linkedin.com/company/nexstride-robotics/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon linkedin"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
  
        <hr className="footer-divider" />
        {/*------------------------------Copy Rights----------------------------- */}
        <p className="footer-copy">
          &copy; {new Date().getFullYear()} Procyon TechSolutions Pvt Ltd. All Rights Reserved.
        </p>
      </footer>
    );
  }
  
  export default Footer;