import React, { useState, useEffect, useRef } from 'react';
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import navigation from '../Assests/Images/AMR images/natural_nav.png';
import loadedTrollyImage from '../Assests/Images/AMR images/Load_500Kgs.png';
import speedTravo from '../Assests/Images/AMR images/Load.png';
import '../Styles/test.css';


const FloatingButton = ({ onClick, icon, style }) => (
  <button
    onClick={onClick}
    className='travoSliderArrow'
    style={{
      ...style,
    }}
  >
    <FontAwesomeIcon icon={icon} style={{ color: "#fff", fontSize: "20px" }} />
  </button>
);

export const data = [

  {
    cover: loadedTrollyImage, 
    title: "Travo Loaded Trolly",
  },
  {
    cover: navigation,
    title: "Navigation Travo",
  },
  {
    cover: speedTravo,
    title: "Speed Travo",
  },

];

const Test = () => {
  const ref = React.useRef();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        ref.current?.goNext();
      }
    }, 3000); 

    return () => clearInterval(interval); 
  }, [isHovered]);

  return (
    <div>
      <div style={{ width: "100%", position: "relative" }}>
        <ResponsiveContainer
          carouselRef={ref}
          render={(parentWidth, carouselRef) => {
            let currentVisibleSlide = 3;
            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={(props) => (
                  <Card {...props} setIsHovered={setIsHovered} />
                )}
                slideWidth={parentWidth < 800 ? parentWidth - 100 : 750}
                carouselWidth={parentWidth}
                data={data}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={5}
                useGrabCursor
                
              />
            );
          }}
        />
        <>
          <FloatingButton
            onClick={() => {
              ref.current?.goBack();
            }}
            icon={faArrowLeft}
            style={{ left: 10 }}
          />
          <FloatingButton
            onClick={() => {
              ref.current?.goNext(6);
            }}
            icon={faArrowRight}
            style={{ right: 10 }}
          />
        </>
      </div>
    </div>
  );
};

export const Card = React.memo(function (props) {
  const { data, dataIndex, setIsHovered } = props;
  const { cover } = data[dataIndex];

  return (
    <div
      style={{
        width: "100%",
        userSelect: "none",
      }}
      className="my-slide-component"
      onMouseEnter= {() => setIsHovered(true)} 
      onMouseLeave= {() => setIsHovered(false)} 
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "fill",
          borderRadius: 30,
        }}
        draggable={false}
        src={cover}
      />
    </div>
  );
});

export default Test;
