import React, { useState, useEffect, useRef } from 'react';
import '../Styles/FleetManager.css'
import { motion } from "framer-motion";

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay, Pagination } from 'swiper/modules';

import TrackPathIcon from '../Assests/Images/Icons/tracking.png'
import PathMasterIcon from '../Assests/Images/Icons/road-map.png';
import TaskFlowIcon from '../Assests/Images/Icons/project-management.png';
import RoboPulseIcon from '../Assests/Images/Icons/health-care.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Navbar from '../Layouts/Navbar';
import Footer from '../Layouts/Footer';

import ConnectWithUsPopUp from '../Layouts/ConnectWithUsPopUp';


const FleetManager = () => {


    const AdvantagesImages = [TrackPathIcon, PathMasterIcon, TaskFlowIcon, RoboPulseIcon];
    const swiperRef = useRef(null);


    useEffect(() => {

        const swiper = swiperRef.current.swiper;
        if (swiper) {
            const handleMouseEnter = () => {
                swiper.autoplay.stop();
            };

            const handleMouseLeave = () => {
                swiper.autoplay.start();
            };

            const slides = document.querySelectorAll('.swiper-slide');
            slides.forEach(slide => {
                slide.addEventListener('mouseenter', handleMouseEnter);
                slide.addEventListener('mouseleave', handleMouseLeave);
            });

            return () => {
                slides.forEach(slide => {
                    slide.removeEventListener('mouseenter', handleMouseEnter);
                    slide.removeEventListener('mouseleave', handleMouseLeave);
                });
            };
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    {/*------------------------------------------------------ Fleet Home Text Animation----------------------------------------------------------------------------------*/}
    const splitText = (text) => {
        return text.split('').map((char, index) => (
            <motion.span
                key={index}
                initial={{ x: -20, opacity: 0 }} 
                animate={{ x: 0, opacity: 1 }} 
                transition={{ duration: 0.5, delay: index * 0.02 }} 
            >
                {char}
            </motion.span>
        
        
        ));
    };




    return (
 
                <div>
                 {/*------------------------------------------------------ Navbar ----------------------------------------------------------------------------------*/}
                  <Navbar/>

                    {/*------------------------------------------------------ Fleet Software Home ----------------------------------------------------------------------------------*/}
                    <section className="fleetHome">
                        <div className='fleetHomeBg'>
                            <h1>{splitText('NXS Fleet Manager')}</h1>
                            <p>{splitText('Streamline autonomy with smart scheduling, real-time tracking, and safer operations.')}</p>
                        </div>
                    </section>
                    <section className="FleetContent">
                        <div className="Fleetgradient">
                            <div className="FleetContentOuter">
                                <p>
                                    NXS Fleet Manager provides the user fine grain control over every aspect of
                                    the robot to ensure smooth operation. Travo-500 and NXS Fleet Manager will
                                    ensure improved productivity and reduced down-times, increasing throughput.
                                </p>
                            </div>
                        </div>

                        {/*------------------------------------------------------ Fleet Software Swiper ----------------------------------------------------------------------------------*/}

                        <div>
                            <div className="fleet-swiper">
                                <div className='collection'>
                                    <Swiper
                                        ref={swiperRef}
                                        modules={[EffectCoverflow, Autoplay, Pagination]}
                                        effect="coverflow"
                                        grabCursor
                                        centeredSlides
                                        loop
                                        slidesPerView='auto'
                                        coverflowEffect={{
                                            rotate: 0,
                                            stretch: 0,
                                            depth: 150,
                                            modifier: 2.5,
                                            slideShadows: false,
                                        }}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                        }}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide key={1} className="swipercontent">

                                            <div class="grid-container1">
                                                <div className="grid-item1">
                                                    <img src="/Icons/PathConfigIcon.svg" alt="Content Icon" loading='lazy' className="circle-image" />
                                                </div>
                                                <div className='SwipperContent'>
                                                    <h3>Path Configuration</h3>
                                                    <p>
                                                        Optimize robot routes with <b>flexible path creation tools</b>, enabling seamless navigation in dynamic environments.
                                                        Easily define <b>restricted zones</b> for safety, preventing robots from entering hazardous areas or interfering with sensitive operations.
                                                        These features ensure efficient task execution and enhanced safety across your workspace.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide key={2} className="swipercontent">

                                            <div class="grid-container1">
                                                <div class="grid-item1"><img src="/Icons/FleetDashboardIcon.svg" alt="Content Icon" loading='lazy' className="circle-image" /></div>
                                                <div class="SwipperContent">
                                                    <h3>Fleet Management Dashboard</h3>
                                                    <p>A <b>centralized control hub </b>that provides comprehensive visibility and control of all robots in your fleet.
                                                        Monitor their status, manage tasks, and analyze performance in one intuitive interface.
                                                        The dashboard simplifies fleet coordination, making it ideal for complex multi-robot environments.</p>
                                                </div>

                                            </div>



                                        </SwiperSlide>
                                        <SwiperSlide key={3} className="swipercontent" >
                                            <div class="grid-container1">
                                                <div className="grid-item1">
                                                    <img src="/Icons/IOT4.0Icon.svg" alt="Content Icon" loading='lazy' className="circle-image" />
                                                </div>
                                                <div className='SwipperContent'>
                                                    <h3>IOT, Industry 4.0 ready</h3>
                                                    <p>Designed for the future of industrial operations, the system integrates seamlessly with <b>smart devices and IoT ecosystems</b>.
                                                        Benefit from <b>real-time data exchange</b>, <b>advanced automation</b>, and <b>predictive analytics </b>to improve operational efficiency and scalability.
                                                        It empowers businesses to stay connected and competitive in the era of Industry 4.0.</p>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide key={4} className="swipercontent">
                                            <div class="grid-container1">
                                                <div className="grid-item1">
                                                    <img src="/Icons/MissionSchedulingIcon.svg" alt="Content Icon" loading='lazy' className="circle-image" />
                                                </div>
                                                <div className='SwipperContent'>
                                                    <h3>Mission Scheduling</h3>
                                                    <p>Simplify task planning with <b>advanced scheduling tools </b>that allow you to prioritize and allocate tasks efficiently. Schedule recurring missions, assign tasks to specific robots, and adapt to changing priorities with ease.
                                                        This feature ensures that operations run smoothly without delays.</p>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide key={5} className="swipercontent">
                                            <div class="grid-container1">
                                                <div className="grid-item1">
                                                    <img src='/Icons/RobotRealTimeIcon.svg' alt="Content Icon" loading='lazy' className="circle-image" />
                                                </div>
                                                <div className='SwipperContent'>
                                                    <h3>Real-time Robot Updates</h3>
                                                    <p>Stay informed with <b>live updates on robot activities</b>, including their current tasks, locations, and health metrics.
                                                        This level of transparency improves <b>operational visibility</b>, enabling quicker decisions and proactive issue resolution.</p>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide key={6} className="swipercontent">

                                            <div class="grid-container1">
                                                <div className="grid-item1">
                                                    <img src="/Icons/PredictiveAnalyticsIcon.svg" alt="Content Icon" loading='lazy' className="circle-image" />
                                                </div>
                                                <div className='SwipperContent'>
                                                    <h3>Predictive Maintenance Alerts</h3>
                                                    <p>Avoid unexpected downtime with <b>intelligent maintenance alerts</b>.
                                                        The system monitors robot performance in real time and provides early warnings for potential issues, allowing you to schedule maintenance proactively.
                                                        This feature reduces costs and ensures the long-term reliability of your fleet.</p>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>


                        {/*------------------------------------------------------ Connect With Us ----------------------------------------------------------------------------------*/}
                        <ConnectWithUsPopUp/>

                        
                        {/*------------------------------------------------------ Fleet Software Advantages----------------------------------------------------------------------------------*/}
                        <div className="AdvantagesDiv">
                            {[
                                { title: 'TrackMate', image: '../Assests/Images/Icons/tracking.png', description: 'A dynamic live map tracks robot positions in real-time, enabling seamless monitoring & planning.' },
                                { title: 'PathMaster', image: { PathMasterIcon }, description: 'Simplifying waypoint and path creation for seamless navigation.' },
                                { title: 'TaskFlow', image: { TaskFlowIcon }, description: 'Automate and optimize workflows with seamless task sequences and smart path management.' },
                                { title: 'RoboPulse', image: { RoboPulseIcon }, description: 'Track robot health and performance in real-time.' }
                            ].map((feature, index) => (
                                <motion.div
                                    key={index}
                                    className={`fleetcard fleetcard-${index}`}
                                    // style={{ marginLeft: `${index * 100}px` }}
                                    initial={{ opacity: 0, x: -50 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: index * 0.3, duration: 0.5 }}
                                >
                                    <img
                                        src={AdvantagesImages[index]}
                                        alt={`${feature.title} Icon`}
                                        className="fleetcard-icon"
                                    />
                                    <div className="fleetcard-content">
                                        <h3>{feature.title}</h3>
                                        <p>{feature.description}</p>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                        

                    </section>

                    {/*------------------------------------------------------ Footer ----------------------------------------------------------------------------------*/}
                    <Footer/>
                </div>
      
    );
};


export default FleetManager;


